import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import errorAndSuccesDefaultMessage from '@core/app-config/errorAndSuccesDefaultMessage'

export default function useUsersList() {
  // Use toast
  const toast = useToast()

  const refUserListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    {
      label: '#', key: 'id', sortable: true, tdClass: 'organisation_id',
    },
    { label: 'Gebruikersnaam', key: 'user', sortable: false },
    { label: 'Organisatie', key: 'companyID', sortable: true },
    { label: 'Rol', key: 'role', sortable: true },
    { label: 'Rol', key: 'role', sortable: true },
    { label: 'TFA', key: 'tfaEnabled', sortable: true },
    { label: 'Acties', key: 'actions' },
  ]
  const perPage = ref(10)
  const totalUsers = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(false)
  const roleFilter = ref(null)
  const organisationFilter = ref(null)
  const statusFilter = ref(null)
  const tfaFilter = ref(null)
  const showOverlay = ref(true)

  const dataMeta = computed(() => {
    const localItemsCount = refUserListTable.value ? refUserListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalUsers.value,
    }
  })

  const refetchData = () => {
    refUserListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery, roleFilter, organisationFilter, statusFilter, tfaFilter], () => {
    refetchData()
  })

  const fetchUsers = (ctx, callback) => {
    store
      .dispatch('apps-users-list/fetchUsers', {
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortDesc: isSortDirDesc.value,
        sortBy: sortBy.value,
        role: roleFilter.value,
        companyID: organisationFilter.value,
        status: statusFilter.value,
        tfaEnabled: tfaFilter.value,
      })
      .then(response => {
        const { items, _meta } = response.data

        callback(items)
        showOverlay.value = false
        totalUsers.value = _meta.totalCount
      })
      .catch(() => {
        showOverlay.value = false
        toast({
          component: ToastificationContent,
          props: {
            title: 'Ophalen gebruikers',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: `${errorAndSuccesDefaultMessage.fetchDataError}`,
          },
        })
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveUserRoleVariant = role => {
    if (role === 11) return 'primary'
    if (role === 10) return 'success'
    if (role === 2) return 'warning'
    if (role === 1) return 'danger'
    return 'primary'
  }

  const resolveUserRoleIcon = role => {
    if (role === 1) return 'ServerIcon'
    if (role === 2) return 'Edit2Icon'
    if (role === 10) return 'SettingsIcon'
    if (role === 11) return 'UserIcon'
    return 'UserIcon'
  }

  const resolveUserRoleName = role => {
    if (role === 1) return 'Administrator'
    if (role === 2) return 'Backoffice Medewerker'
    if (role === 10) return 'Organisatie Administrator'
    if (role === 11) return 'Agent'
    return ''
  }

  const resolveUserStatusVariant = status => {
    if (status === 8) return 'warning'
    if (status === 9) return 'secondary'
    if (status === 10) return 'success'
    return 'primary'
  }

  const resolveUserStatusName = status => {
    if (status === 8) return 'Pending'
    if (status === 9) return 'Inactief'
    if (status === 10) return 'Actief'
    return ''
  }

  const roleOptions = [
    { label: 'Administrator', value: 1 },
    { label: 'Backoffice Medewerker', value: 2 },
    { label: 'Organisatie Administrator', value: 10 },
    { label: 'Agent', value: 11 },
  ]

  const organisationOptions = [
    { label: 'Self Reliance', value: 'self-reliance' },
  ]

  const statusOptions = [
    { label: 'Actief', value: 10 },
    { label: 'Inactief', value: 9 },
  ]

  const statusFilterOptions = [
    { label: 'Actief', value: 10 },
    { label: 'Inactief', value: 9 },
    { label: 'Pending', value: 8 },
  ]

  const tfaOptions = [
    { label: 'Uitgeschakeld', value: 0 },
    { label: 'Ingeschakeld', value: 1 },
  ]

  return {
    fetchUsers,
    tableColumns,
    perPage,
    currentPage,
    totalUsers,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refUserListTable,

    resolveUserRoleVariant,
    resolveUserRoleIcon,
    resolveUserStatusVariant,
    refetchData,
    resolveUserStatusName,
    resolveUserRoleName,

    // Extra Filters
    roleFilter,
    organisationFilter,
    statusFilter,

    roleOptions,
    organisationOptions,
    statusOptions,
    tfaOptions,
    tfaFilter,
    showOverlay,
    statusFilterOptions,
  }
}
